import { IconMenuInStore, IconMenuOnline } from '@ee-monorepo/shared/ui/icons';
import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import React from 'react';
import { SegmentedControl } from '@ee-monorepo/shared/ui/segmented-control';

interface SwitchesProps {
  activeIndex: number;
  className?: string;
}

export const Switches = ({ activeIndex, className }: SwitchesProps) => {
  const translate = useTranslations();
  const router = useRouter();
  const inStore = translate('shared.in_store_name');
  const online = translate('shared.online_name');

  const handleChange = (index: number) => {
    if (index === 0) {
      const findStoresPath =
        sessionStorage.getItem('inStorePrevQuery') || '/find-stores';
      router.push(findStoresPath);
    } else {
      const { asPath } = router;
      sessionStorage.setItem('inStorePrevQuery', asPath);
      router.push('/online-stores');
    }
  };

  return (
    <SegmentedControl
      items={[
        {
          iconComponent: <IconMenuInStore />,
          label: inStore,
        },
        {
          iconComponent: <IconMenuOnline />,
          label: online,
        },
      ]}
      onSelect={handleChange}
      activeItemIndex={activeIndex}
      size="normal"
      className={'w-1/2 uppercase'}
      wrapperClassName={className}
    />
  );
};
